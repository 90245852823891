import moment from "moment";
import React from "react";
import { createContext, useCallback, useEffect, useState } from "react";
import { useAxios } from "../hooks/useAxios";
import { ApiResponse } from "../models/ApiResponse";
import { Customer } from "../models/Customers";

import { Machine } from "../models/Machine";
import {
  FormattedServiceCall,
  ServiceCall,
  serviceCallStatusDescription,
} from "../models/ServiceCall";
import { User } from "../models/User";
import { CallsFilter } from "../models/CallsFilter";

type DataContextType = {
  machines?: Machine[];
  customers?: Customer[];
  serviceCalls?: ServiceCall[];
  users?: User[];
  callsFilter?: CallsFilter;

  machinesLoading: boolean;
  customersLoading: boolean;
  serviceCallsLoading: boolean;
  usersLoading: boolean;

  fetchMachines: () => void;
  fetchCustomers: () => void;
  fetchServiceCalls: () => void;
  fetchUsers: () => void;

  refetchAll: () => void;
  setCallsFilter: (filter: CallsFilter) => void;

  clearAll: () => void;

  //derived
  formattedServiceCalls?: FormattedServiceCall[];
};

export const DataContext = createContext<DataContextType>({
  customersLoading: false,
  machinesLoading: false,
  serviceCallsLoading: false,
  usersLoading: false,
  callsFilter: {},

  fetchMachines: () => {},
  fetchCustomers: () => {},
  fetchServiceCalls: () => {},
  fetchUsers: () => {},
  refetchAll: () => {},

  clearAll: () => {},

  setCallsFilter: (filter: CallsFilter) => {},
});

type Props = {
  children: React.ReactNode;
};

function DataProvider({ children }: Props) {
  const axios = useAxios();

  const [customersLoading, setCustomersLoading] = useState(false);
  const [machinesLoading, setMachinesLoading] = useState(false);
  const [serviceCallsLoading, setServiceCallsLoading] = useState(false);
  const [usersLoading, setUsersLoading] = useState(false);

  const [machines, setMachines] = useState<Machine[] | undefined>();
  const [customers, setCustomers] = useState<Customer[] | undefined>();
  const [serviceCalls, setServiceCalls] = useState<ServiceCall[] | undefined>();
  const [users, setUsers] = useState<User[] | undefined>();

  // const [_callFilter, _setCallFilter] = useState<CallsFilter>({
  //   start: moment().year(2023).month(0).date(1).format("YYYY-MM-DD"),
  //   end: moment().format("YYYY-MM-DD"),
  // });
  // Change into dynamic mode with a range of three(3) months
  const [_callFilter, _setCallFilter] = useState<CallsFilter>({
    start: moment().subtract(3, 'months').format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
});

  const setCallsFilter = useCallback(
    (filter: CallsFilter) => {
      _setCallFilter(filter);
    },
    [_setCallFilter]
  );

  const formattedServiceCalls = React.useMemo(
    () =>
      serviceCalls
        ?.map((sc) => {
          const machine = machines?.find((mac) => mac.id === sc.machine_id);

          const customer = customers?.find(
            (cs) => cs.id === machine?.customer_id
          );

          const assignee =
            sc.assigned_to !== null
              ? users?.find((u) => u.id === sc.assigned_to)
              : undefined;

          const fsc: FormattedServiceCall = {
            id: sc.id,
            assigned_to: assignee,
            created: moment(sc.created).toLocaleString(),
            time_since_creation: moment(sc.created).fromNow(),
            time_since_last_update: moment(sc.status_updated).fromNow(),
            created_by_email: sc.created_by?.email,
            created_by_name: sc.created_by?.name,
            description: sc.description,
            machine,
            customer,
            status: serviceCallStatusDescription(sc.status),
            call: sc,
          };

          return fsc;
        })
        .sort((left, right): number => {
          let result = 0;

          if (left.call.vip) {
            if (!right.call.vip) {
              return -1;
            }
          } else {
            if (right.call.vip) {
              return 1;
            }
          }

          result = moment(left.call.created).diff(moment(right.call.created));

          return result;
        }),
    [users, customers, serviceCalls, machines]
  );

  const clearAll = () => {
    setMachines(undefined);
    setServiceCalls(undefined);
    setUsers(undefined);
  };

  const fetchMachines = useCallback(() => {
    axios
      .get<ApiResponse<Machine[]>>("machines/get")
      .then((res) => {
        const responseData = res.data;
        if (responseData.success) {
          setMachines(responseData.data);
        }
      })
      .finally(() => {
        setMachinesLoading(false);
      });
  }, [axios]);

  const fetchCustomers = useCallback(() => {
    axios
      .get<ApiResponse<Customer[]>>("customers/get")
      .then((res) => {
        const responseData = res.data;
        if (responseData.success) {
          setCustomers(responseData.data);
        }
      })
      .finally(() => {
        setCustomersLoading(false);
      });

    fetchMachines();
  }, [axios, fetchMachines]);

  const fetchUsers = useCallback(() => {
    axios
      .get<ApiResponse<User[]>>("users/get")
      .then((res) => {
        const responseData = res.data;
        if (responseData.success) {
          setUsers(responseData.data);
        }
      })
      .finally(() => {
        setUsersLoading(false);
      });
  }, [axios]);

  const fetchServiceCalls = useCallback(() => {
    const params =
      _callFilter.start && _callFilter.end
        ? `?start=${_callFilter.start}&end=${_callFilter.end}`
        : "";
    axios
      .get<ApiResponse<ServiceCall[]>>(`calls/get${params}`)
      .then((res) => {
        const responseData = res.data;
        if (responseData.success) {
          setServiceCalls(responseData.data);
        }
      })
      .finally(() => {
        setServiceCallsLoading(false);
      });
  }, [axios, _callFilter]);

  const refetchAll = useCallback(() => {
    fetchServiceCalls();
    fetchUsers();
    fetchCustomers();
    fetchMachines();
  }, [fetchCustomers, fetchMachines, fetchUsers, fetchServiceCalls]);

  useEffect(() => {
    if (!serviceCalls) {
      fetchServiceCalls();
    }
    if (!users) {
      fetchUsers();
    }

    if (!customers) {
      fetchCustomers();
    }

    if (!machines) {
      fetchMachines();
    }
  }, [
    users,
    customers,
    serviceCalls,
    machines,
    fetchCustomers,
    fetchMachines,
    fetchUsers,
    fetchServiceCalls,
  ]);

  return (
    <DataContext.Provider
      value={{
        customersLoading,
        machinesLoading,
        serviceCallsLoading,
        usersLoading,

        fetchCustomers,
        fetchMachines,
        fetchServiceCalls,
        fetchUsers,
        refetchAll,
        setCallsFilter,
        clearAll,

        machines,
        customers,
        serviceCalls,
        users,
        callsFilter: _callFilter,
        formattedServiceCalls,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}

export default DataProvider;
